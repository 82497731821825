import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./App";
import { ThemeProvider } from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
