import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";

function PageWrapper(props) {
  return (
    <Wrapper>
      {props.children}
      <Footer />
    </Wrapper>

  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default PageWrapper;
