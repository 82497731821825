import React from "react";
import {
  createHashRouter,
  RouterProvider
} from "react-router-dom";

import HomePage from "./pages/HomePage";
import Empresa from "./pages/Empresa";
import Productos from "./pages/Productos";
import Calidad from "./pages/Calidad";
import Contacto from "./pages/Contacto";

const router = createHashRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "empresa",
    element: <Empresa />,
  },
  {
    path: "productos",
    element: <Productos />,
  },
  {
    path: "calidad",
    element: <Calidad />,
  },
  {
    path: "contacto",
    element: <Contacto />,
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
